import { createContext } from "react";
import useFirebase from "../hooks/useFirebase";


export const FirebaseContext = createContext(null);

const FirebaseProvider = ({ children }) => {

  useFirebase()
  
  return (
    <FirebaseContext.Provider value={null}>
      {children}
    </FirebaseContext.Provider>
  );
}

export default FirebaseProvider;