import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { useEffect } from "react";

const useFirebase = () => {

  console.log('useFirebase hook is working')

  useEffect(() => {
    initializeFirebase()
  },[])

  const initializeFirebase = async () => {
    const firebaseConfig = {
      apiKey: "AIzaSyBoLIs3lS1fV4iUBc9pRfyFhaw0jO6j-mo",
      authDomain: "forus-pe-fapp-test-noti.firebaseapp.com",
      projectId: "forus-pe-fapp-test-noti",
      storageBucket: "forus-pe-fapp-test-noti.appspot.com",
      messagingSenderId: "282115379070",
      appId: "1:282115379070:web:1b546945a27a7e70792d2f",
    };
    
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    
    // Initialize Firebase Cloud Messaging and get a reference to the service
    const messaging = getMessaging(app);
    const isPermissionGranted = await requestPermission()

    // Check if the user has granted permission to receive notifications
    if(!isPermissionGranted) {
      return
    }

    // Get the registration token. Initially this makes a network call, once retrieved
    // subsequent calls to getToken will return from cache.
    const currentToken = await getToken(messaging,{ vapidKey: 'BFHc7NYVLJHAkN_Nkem9wWG4paquWV2gj3Yrh2xN7hqqHL7Z4CXouqtP3bn5cVLs8ResOoWDfovA8wXTqIoKNgA' })

    onMessage(messaging, (payload) => {
      console.log('Message received. ', payload);
      // ...
    });
    

    console.log("currentToken -> ",currentToken)
  }

  const requestPermission = async () => {
    console.log('Requesting permission...');

    const permission = await Notification.requestPermission()

    if (permission === 'granted') {
      console.log('Notification permission granted.');
      return true
    }

    console.log('Unable to get permission to notify.');
    return false
  }
  
  return null

}

export default useFirebase